import React from "react";
import { Page } from "../../components/Page";
import { Title } from "../../components/Title";
import { Content } from "../../components/Content";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const SlippingPreventionPage = () => (
  <Page language="sv" page="Minska halkrisken">
    <Row>
      <Col>
        <Title>Minska halkrisken</Title>

        <Content>
          Halkolyckor bland fotgängare är mycket vanliga. Svårighetsgraden hos
          halkolyckor varierar från mycket lätta fall till svåra, till och med
          dödsfall. Ofta vrickar man foten när man halkar, men även benen
          utsätts för påfrestning. Vid fall bryts i synnerhet benen i handleden
          och höftområdet lätt. Man kan få en sträckning i ryggen bara av en
          hastig rörelse för att korrigera balansen. Risken att invalidiseras
          ökar med åldern. Risken för en 40−50-åring är upp till dubbelt så stor
          som för en 20−29-åring.
        </Content>

        <Content>Så här undviker du att halka:</Content>

        <Content as="ol">
          <li>
            Gå FÖRSIKTIGT när väglaget är halt, för alla ställen har inte
            nödvändigtvis blivit sandade eller sanden har kunnat sjunka in i
            isen.
          </li>
          <li>
            Välj skor som är lämpade för vinterförhållanden och använd
            HALKSKYDD.
          </li>
        </Content>

        <Content>
          <a href="https://www.kotitapaturma.fi/sv/">
            Hemsida för kampanjen mot hemolyckor
          </a>
        </Content>
      </Col>
    </Row>
  </Page>
);

export default SlippingPreventionPage;
